import { Currency, ETHER, JSBI, TokenAmount } from '@uniswap/sdk'
import React, { useCallback, useEffect, useState } from 'react'
import { Plus } from 'react-feather'
import { Text } from 'rebass'
import { useTranslation } from 'react-i18next'
import { ButtonDropdownGreen } from '../../components/Button'
import { LightCard } from '../../components/Card'
import { AutoColumn, ColumnCenter } from '../../components/Column'
import CurrencyLogo from '../../components/CurrencyLogo'
import { FindPoolTabs } from '../../components/NavigationTabs'
import { MinimalPositionCard } from '../../components/PositionCard'
import Row from '../../components/Row'
import CurrencySearchModal from '../../components/SearchModal/CurrencySearchModal'
import { PairState, usePair } from '../../data/Reserves'
import { useActiveWeb3React } from '../../hooks'
import { usePairAdder } from '../../state/user/hooks'
import { useTokenBalance } from '../../state/wallet/hooks'
import { StyledInternalLink } from '../../theme'
import { currencyId } from '../../utils/currencyId'
import AppBody from '../AppBody'
import { Dots } from '../Pool/styleds'
// import { BlueCard } from '../../components/Card'
// import { TYPE } from '../../theme'
import styled from 'styled-components'

import { LayoutCenter } from '../Swap'
import { SwapPoolTabs } from '../../components/NavigationTabs'

enum Fields {
  TOKEN0 = 0,
  TOKEN1 = 1
}

const StyledLiquidityActiveLink = styled(StyledInternalLink)`
  color: #00bfa0;
`
const StyledTipText = styled(Text)`
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  color: #606080;
`

export default function PoolFinder() {
  const { t } = useTranslation()
  const { account } = useActiveWeb3React()

  const [showSearch, setShowSearch] = useState<boolean>(false)
  const [activeField, setActiveField] = useState<number>(Fields.TOKEN1)

  const [currency0, setCurrency0] = useState<Currency | null>(ETHER)
  const [currency1, setCurrency1] = useState<Currency | null>(null)

  const [pairState, pair] = usePair(currency0 ?? undefined, currency1 ?? undefined)
  const addPair = usePairAdder()
  useEffect(() => {
    if (pair) {
      addPair(pair)
    }
  }, [pair, addPair])

  const validPairNoLiquidity: boolean =
    pairState === PairState.NOT_EXISTS ||
    Boolean(
      pairState === PairState.EXISTS &&
        pair &&
        JSBI.equal(pair.reserve0.raw, JSBI.BigInt(0)) &&
        JSBI.equal(pair.reserve1.raw, JSBI.BigInt(0))
    )

  const position: TokenAmount | undefined = useTokenBalance(account ?? undefined, pair?.liquidityToken)
  const hasPosition = Boolean(position && JSBI.greaterThan(position.raw, JSBI.BigInt(0)))
  const handleCurrencySelect = useCallback(
    (currency: Currency) => {
      if (activeField === Fields.TOKEN0) {
        setCurrency0(currency)
      } else {
        setCurrency1(currency)
      }
    },
    [activeField]
  )

  const handleSearchDismiss = useCallback(() => {
    setShowSearch(false)
  }, [setShowSearch])

  const prerequisiteMessage = (
    <LightCard padding="24px 10px">
      <StyledTipText textAlign="center">
        {!account ? t('exchange.connectWalletFindPools') : t('exchange.selectTokenFindYourLiquidity')}
      </StyledTipText>
    </LightCard>
  )

  return (
    <LayoutCenter>
      <SwapPoolTabs active={'pool'} />
      <AppBody>
        <FindPoolTabs />
        <AutoColumn style={{ padding: '16px 24px' }} gap="md">
          <ButtonDropdownGreen
            onClick={() => {
              setShowSearch(true)
              setActiveField(Fields.TOKEN0)
            }}
          >
            {currency0 ? (
              <Row>
                <CurrencyLogo currency={currency0} />
                <Text fontWeight={500} fontSize={20} marginLeft={'12px'}>
                  {currency0.symbol}
                </Text>
              </Row>
            ) : (
              <Text fontWeight={500} fontSize={20} marginLeft={'12px'}>
                {t('exchange.selectToken')}
              </Text>
            )}
          </ButtonDropdownGreen>

          <ColumnCenter>
            <Plus size="16" color="#888D9B" />
          </ColumnCenter>

          <ButtonDropdownGreen
            onClick={() => {
              setShowSearch(true)
              setActiveField(Fields.TOKEN1)
            }}
          >
            {currency1 ? (
              <Row>
                <CurrencyLogo currency={currency1} />
                <Text fontWeight={500} fontSize={20} marginLeft={'12px'}>
                  {currency1.symbol}
                </Text>
              </Row>
            ) : (
              <Text fontWeight={500} fontSize={20} marginLeft={'12px'}>
                {t('exchange.selectToken')}
              </Text>
            )}
          </ButtonDropdownGreen>

          {hasPosition && (
            <ColumnCenter
              style={{ justifyItems: 'center', backgroundColor: '', padding: '12px 0px', borderRadius: '12px' }}
            >
              <Text textAlign="center" fontWeight={500}>
                {t('exchange.poolFound')}
              </Text>
              <StyledLiquidityActiveLink to={`/pool`}>
                <Text textAlign="center">{t('exchange.manageThisPool')}</Text>
              </StyledLiquidityActiveLink>
            </ColumnCenter>
          )}

          {currency0 && currency1 ? (
            pairState === PairState.EXISTS ? (
              hasPosition && pair ? (
                <MinimalPositionCard pair={pair} border="1px solid #CED0D9" />
              ) : (
                <LightCard padding="24px 10px">
                  <AutoColumn gap="sm" justify="center">
                    <StyledTipText textAlign="center">{t('exchange.youPoolDontHaveLiquidity')}</StyledTipText>
                    <StyledLiquidityActiveLink to={`/add/${currencyId(currency0)}/${currencyId(currency1)}`}>
                      <Text textAlign="center">{t('exchange.addLiquidity')}</Text>
                    </StyledLiquidityActiveLink>
                  </AutoColumn>
                </LightCard>
              )
            ) : validPairNoLiquidity ? (
              <LightCard padding="24px 10px">
                <AutoColumn gap="sm" justify="center">
                  <StyledTipText textAlign="center">{t('exchange.noPoolFound')}</StyledTipText>
                  <StyledLiquidityActiveLink to={`/add/${currencyId(currency0)}/${currencyId(currency1)}`}>
                    {t('exchange.createPool')}
                  </StyledLiquidityActiveLink>
                </AutoColumn>
              </LightCard>
            ) : pairState === PairState.INVALID ? (
              <LightCard padding="24px 10px">
                <AutoColumn gap="sm" justify="center">
                  <StyledTipText textAlign="center" fontWeight={500}>
                    {t('exchange.invalidPair')}
                  </StyledTipText>
                </AutoColumn>
              </LightCard>
            ) : pairState === PairState.LOADING ? (
              <LightCard padding="24px 10px">
                <AutoColumn gap="sm" justify="center">
                  <StyledTipText textAlign="center">
                    {t('global.loading')}
                    <Dots />
                  </StyledTipText>
                </AutoColumn>
              </LightCard>
            ) : null
          ) : (
            prerequisiteMessage
          )}
        </AutoColumn>

        <CurrencySearchModal
          isOpen={showSearch}
          onCurrencySelect={handleCurrencySelect}
          onDismiss={handleSearchDismiss}
          showCommonBases
          selectedCurrency={(activeField === Fields.TOKEN0 ? currency1 : currency0) ?? undefined}
        />
      </AppBody>
    </LayoutCenter>
  )
}
